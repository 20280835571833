import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomButton from '../../react-native-material-wrapper/button.component';
import { LoadingDots } from '../loading-dots/loading-dots.component';
import { ColorType } from '../../../assets/colors/colors.g';
import { useSelector } from 'react-redux';
import { getTheme } from '../../../stores/selectors/theme.selector';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 425,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: '#fff',
    boxSizing: 'border-box',
    '& button': {
      width: '100% !important',
    },
  },
  buttonWrapper: {
    padding: '8px 16px 34px',
  },
  buttonStyles: {
    color: '#ffffff',
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    '& .MuiButton-contained.Mui-disabled': {
      background: ColorType.GREY0,
      color: ColorType.GREY3,
    },
  },
}));
interface IProps {
  label: string;
  action: Function;
  isDisabled: boolean;
  loading?: boolean;
}
const Button = ({ label, action, isDisabled, loading = false }: IProps) => {
  const theme = useSelector(getTheme);
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Divider />
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: isDisabled
              ? ColorType.GREY0
              : theme.colorPalettes.primary,
          }}
          onClick={action}
          disabled={isDisabled}
          loading={true}
          disableElevation
        >
          {loading ? <LoadingDots /> : label}
        </CustomButton>
      </div>
    </Grid>
  );
};
export default Button;
