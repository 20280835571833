import * as React from 'react';
const SvgConnectorTesla = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      d='M5 7.5c0-.458.375-.833.833-.833.459 0 .834.375.834.833a.836.836 0 0 1-.834.833A.836.836 0 0 1 5 7.5Zm9.167.833A.836.836 0 0 0 15 7.5a.836.836 0 0 0-.833-.833.836.836 0 0 0-.834.833c0 .458.375.833.834.833Zm4.166-.058v.892a7.5 7.5 0 0 1-7.5 7.5H9.167a7.485 7.485 0 0 1-7.5-7.5v-.892a4.936 4.936 0 0 1 4.941-4.942h6.784a4.936 4.936 0 0 1 4.941 4.942Zm-6.25-.775a2.084 2.084 0 1 0 4.169-.001 2.084 2.084 0 0 0-4.169.001Zm-8.333 0a2.084 2.084 0 1 0 4.168-.002A2.084 2.084 0 0 0 3.75 7.5Zm3.75 6.667a.836.836 0 0 0-.833-.834.836.836 0 0 0-.834.834c0 .458.375.833.834.833a.836.836 0 0 0 .833-.833Zm3.75 0c0-.692-.558-1.25-1.25-1.25s-1.25.558-1.25 1.25c0 .691.558 1.25 1.25 1.25s1.25-.559 1.25-1.25Zm2.917 0a.836.836 0 0 0-.834-.834.836.836 0 0 0-.833.834c0 .458.375.833.833.833a.836.836 0 0 0 .834-.833Zm2.475-4.45a3.262 3.262 0 0 1-2.475 1.116A3.332 3.332 0 0 1 10.833 7.5c0-1 .45-1.892 1.15-2.5H8.017c.7.608 1.15 1.5 1.15 2.5a3.332 3.332 0 0 1-3.334 3.333 3.25 3.25 0 0 1-2.475-1.116 5.87 5.87 0 0 0 1.675 3.566A8.428 8.428 0 0 1 10 11.667c1.867 0 3.575.608 4.967 1.616a5.87 5.87 0 0 0 1.675-3.566Z'
    />
  </svg>
);
export default SvgConnectorTesla;
