import React from 'react';
import { Grid } from '@mui/material';
import CustomButton from '../react-native-material-wrapper/button.component';
import { useTranslation } from 'react-i18next';
import { authStyles } from './authStyles';
import clsx from 'clsx';
import { ColorType } from '../../assets/colors/colors.g';

const LoginTerms = () => {
  const { i18n } = useTranslation();
  const classes = authStyles();

  const redirectTerms = () => {
    window.open('https://chargelab.co/driver-and-site-host-terms#driver');
  };
  const redirectPolicy = () => {
    window.open(
      'https://chargelab.co/driver-and-site-host-terms#privacy-policy',
    );
  };

  return (
    <Grid className={classes.termsWrapper}>
      <p className={clsx([classes.termsDescription])}>
        {i18n.t('by_loggin_in_acknowledge')}
        <CustomButton
          size='large'
          type='link'
          style={{
            color: ColorType.DENIM,
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
            textAlign: 'center',
            fontFamily: 'Inter',
            textTransform: 'inherit',
            padding: '0px 0px 2px 0px',
          }}
          onClick={redirectTerms}
        >
          {i18n.t('terms_of_service')}
        </CustomButton>{' '}
        {i18n.t('and_text')}{' '}
        <CustomButton
          size='large'
          type='link'
          style={{
            color: ColorType.DENIM,
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
            textAlign: 'center',
            fontFamily: 'Inter',
            textTransform: 'inherit',
            padding: '0px 0px 2px 0px',
          }}
          onClick={redirectPolicy}
        >
          {i18n.t('privacy_policy')}
        </CustomButton>
        {i18n.t('concent_to_receive_message', {
          company: window.domainName,
        })}
      </p>
      <p className={clsx([classes.termsDescription, classes.mt16])}>
        {i18n.t('text_message_rate_varies', {
          company: window.domainName,
        })}
      </p>
    </Grid>
  );
};

export default LoginTerms;
