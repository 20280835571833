import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Button } from '@mui/material';

import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { useDomain } from '../../hooks/useDomain';
import { ROUTES } from '../../types/routes.enum';
import {
  useFetchActivatedRFIDCardsQuery,
  useFetchRFIDCardsQuery,
} from '../../services/rfid.api';
import { RFIDCardComponent } from './rfidCard.component';
import { LoadingSpinner } from '../ui-components/loading-spinner.component';
import { RFIDListingComponent } from './rfidListing/rfidList.component';
import { NUMBER } from '../../constant/number.constant';
import { rfidStyle } from './rfidStyle';
import { getTheme } from '../../stores/selectors/theme.selector';
import { rfidCart, rfidHouse, rfidVector } from '../../assets/icons/icons';
import { ColorType } from '../../assets/colors/colors.g';

export const RFIDComponent = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { domain } = useDomain();
  const classes = rfidStyle();
  const theme = useSelector(getTheme);

  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const { data: orderedCard, isLoading: isOrderedCardDataLoading } =
    useFetchRFIDCardsQuery(undefined);

  const {
    data: activatedCards,
    isLoading: isActivatedCardDataLoading,
    refetch,
  } = useFetchActivatedRFIDCardsQuery(undefined);

  const isDataLoading = isOrderedCardDataLoading || isActivatedCardDataLoading;

  useEffect(() => {
    if (isDataLoading) {
      LoadingSpinner.show();
    } else {
      LoadingSpinner.hide();
    }
  }, [isDataLoading]);

  const handleOrderCardClick = () => {
    const route = `/app/${domain}/${ROUTES.ORDER_CARD}${
      chargerName ? `?charger=${chargerName}` : ''
    }`;
    navigate(route);
  };

  const handleActivateCardClick = () => {
    const route = `/app/${domain}/${ROUTES.ACTIVATE_CARD}${
      chargerName ? `?charger=${chargerName}` : ''
    }`;
    navigate(route);
  };

  const backAction = () => {
    const route = `/app/${domain}/${ROUTES.ACCOUNT}${
      chargerName ? `?charger=${chargerName}` : ''
    }`;
    navigate(route);
  };

  const RFID_BENEFITS = [
    {
      icon: rfidVector(theme.navigationSelectedColor),
      title: i18n.t('rfid_fast_to_charger_title'),
      description: i18n.t('rfid_fast_to_charger_description'),
    },
    {
      icon: rfidCart(theme.navigationSelectedColor),
      title: i18n.t('rfid_easy_to_order_title'),
      description: i18n.t('rfid_easy_to_order_description'),
    },
    {
      icon: rfidHouse(theme.navigationSelectedColor),
      title: i18n.t('rfid_flexible_to_use_title'),
      description: i18n.t('rfid_flexible_to_use_description'),
    },
  ];

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('rfid_cards')}
        onBackArrowClicked={backAction}
      />
      {!isDataLoading && (
        <Box
          component='div'
          style={{
            padding:
              activatedCards?.length > NUMBER.ZERO
                ? '16px'
                : '24px 16px 166px 16px',
          }}
        >
          {activatedCards?.length > NUMBER.ZERO ? (
            <RFIDListingComponent activatedCards={activatedCards} />
          ) : (
            <RFIDCardComponent RFID_BENEFITS={RFID_BENEFITS} />
          )}
        </Box>
      )}
      {!activatedCards?.length && (
        <Grid
          container
          style={{
            padding: '0px 16px 34px 16px',
            maxWidth: '425px',
            position: 'fixed',
            bottom: '0px',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Button
            disabled={!orderedCard?.maxOutstandingCardOrdersReached}
            onClick={() => handleOrderCardClick()}
            className={`${classes.button} ${classes.orderButtonContainer}`}
            style={{
              backgroundColor: !orderedCard?.maxOutstandingCardOrdersReached
                ? ColorType.GREY0
                : theme.colorPalettes.primary,
            }}
            variant='contained'
            color='primary'
          >
            {i18n.t('rfid_order_card')}
          </Button>
          <Button
            onClick={() => handleActivateCardClick()}
            className={`${classes.button} ${classes.activateButtonContainer}`}
            variant='contained'
            color='primary'
            style={{
              background: theme.colorPalettes.light,
              color: theme.colorPalettes.primary,
            }}
          >
            {i18n.t('rfid_activate_card')}
          </Button>
        </Grid>
      )}
    </ViewWrapper>
  );
};
