import { useTranslation } from 'react-i18next';
import { ConnectorType } from '../../../types/charger-connector-type.enum';

import {
  ConnectorCcs,
  ConnectorChademo,
  ConnectorJ1772,
  ConnectorTesla,
  ConnectorType2,
  ConnectorType2Ccs,
} from '../../connectors';

interface ConnectorTypeProps {
  connectorUrl?: string;
  connectorType?: ConnectorType;
  size?: number;
  opacity?: number;
  color?: string;
}

export const ChargerConnectorType = ({
  connectorUrl,
  connectorType,
  size = 36,
  opacity = 1,
  color = '#000',
}: ConnectorTypeProps) => {
  const { i18n } = useTranslation();
  if (connectorUrl) {
    return (
      <img
        style={{
          opacity,
          width: size,
          height: size,
        }}
        src={connectorType}
        alt={i18n.t('connector_type')}
      />
    );
  }

  const getConnectorImageSrc = (type: ConnectorType) => {
    switch (type) {
      case ConnectorType.J1772:
        return ConnectorJ1772;
      case ConnectorType.CCS:
        return ConnectorCcs;
      case ConnectorType.CHADEMO:
        return ConnectorChademo;
      case ConnectorType.TYPE_2:
        return ConnectorType2;
      case ConnectorType.TYPE_2_CCS:
        return ConnectorType2Ccs;
      case ConnectorType.TESLA:
        return ConnectorTesla;
      case ConnectorType.DUAL_J1772:
      case ConnectorType.DUAL_CCS:
      case ConnectorType.CCS_CHADEMO:
      case ConnectorType.TYPE_2_CHADEMO_TYPE_2_CCS:
      default:
        return null;
    }
  };

  if (connectorType) {
    const Connector = getConnectorImageSrc(connectorType);
    if (Connector) {
      return (
        <Connector
          color={color}
          style={{ width: `${size}px`, height: `${size}px` }}
          alt={i18n.t('connector_type')}
        />
      );
    }
  }

  return null;
};
