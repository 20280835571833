import React, { useMemo, useState } from 'react';
import { Box, Typography, IconButton, Stack, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HappyFace from './happy-face.component';
import UnhappyFace from './unhappy-face.component';
import AverageFace from './average-face.component';
import { FeedbackOptions } from './constants';
import Feedback from './feedback.component';
import GoogleRateButton from './google-rate-btn.component';
import { useDomain } from '../../hooks/useDomain';
import { ColorType } from '../../assets/colors/colors.g';
import { usePostHog } from 'posthog-js/react';

interface FeedbackWithOptionsProps {
  transaction: any;
}

const NoTransition = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactElement }
>((props, ref) => {
  const { children } = props;
  return <div ref={ref}>{children}</div>;
});

const FeedbackWithOptions: React.FC<FeedbackWithOptionsProps> = ({
  transaction,
}) => {
  const { i18n } = useTranslation();
  const posthog = usePostHog();

  const [selectedFeedback, setSelectedFeedback] = useState<
    FeedbackOptions | undefined
  >();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    document.body.style.overflow = 'unset';
  };
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);
  const handleSubmit = () => {
    handleClose();
    setIsFeedbackSent(true);
  };
  const isHappySent = useMemo(() => {
    return selectedFeedback === FeedbackOptions.Happy;
  }, [selectedFeedback]);

  const { domain } = useDomain();

  const showGoogleRateButton = useMemo(() => {
    return isHappySent && domain?.toLowerCase() === 'chargelab';
  }, [isHappySent, domain]);

  const handleFeedBackClicked = (option: FeedbackOptions) => {
    setSelectedFeedback(option);
    posthog.capture('session_feedback', {
      transactionId: transaction?.transactionId,
      charger: transaction?.charger?.name,
      startTime: transaction?.startTime,
      endTime: transaction?.stopTime,
      location: `${transaction?.charger?.location?.name} on ${transaction?.charger?.location?.streetAddress} ${transaction?.charger?.location?.city},${transaction?.charger?.location?.stateOrRegion}, ${transaction?.charger?.location?.zipOrPostalCode}`,
      energyDelivered: transaction?.consumedEnergyKilowattHours,
      cost: transaction?.billedTotalAmount,
      satisfaction: option.toString(),
    });
    if (
      option === FeedbackOptions.Happy &&
      domain?.toLowerCase() === 'chargelab'
    ) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const showFeedbackHelpUs = () => {
    return (
      (!isFeedbackSent && selectedFeedback === FeedbackOptions.Average) ||
      (!isFeedbackSent && selectedFeedback === FeedbackOptions.Unhappy) ||
      (!isFeedbackSent &&
        selectedFeedback === FeedbackOptions.Happy &&
        domain?.toLowerCase() !== 'chargelab')
    );
  };

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: '#f2f4f6',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          color: '#4e5968',
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Inter',
          lineHeight: '24px',
        }}
      >
        {isFeedbackSent
          ? i18n.t('feedback_thank_you')
          : i18n.t('feedback_how_today')}
      </Typography>
      {selectedFeedback === FeedbackOptions.Happy &&
        domain?.toLowerCase() === 'chargelab' && (
          <Typography
            sx={{
              textAlign: 'center',
              color: '#202223',
              fontSize: '14px',
              fontFamily: 'Inter',
              fontWeight: 400,
              lineHeight: '20px',
            }}
          >
            {i18n.t('feedback_help_discover')}
          </Typography>
        )}
      {showFeedbackHelpUs() && (
        <Typography
          sx={{
            textAlign: 'center',
            color: '#202223',
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 400,
            lineHeight: '20px',
          }}
        >
          {i18n.t('feedback_help_us')}
        </Typography>
      )}
      <Stack direction='row' spacing={5} justifyContent='center'>
        <IconButton
          disabled={isFeedbackSent}
          onClick={() => handleFeedBackClicked(FeedbackOptions.Happy)}
          sx={{
            width: '58px',
            height: '56px',
            backgroundColor: 'white',
            borderRadius: '16px',
            border: '1px solid #d1d6db',
          }}
        >
          <HappyFace
            color={
              selectedFeedback === FeedbackOptions.Happy
                ? ColorType.POSITIVE
                : '#B0B8C1'
            }
          />
        </IconButton>
        <IconButton
          disabled={isFeedbackSent}
          onClick={() => handleFeedBackClicked(FeedbackOptions.Average)}
          sx={{
            width: '58px',
            height: '56px',
            backgroundColor: 'white',
            borderRadius: '16px',
            border: '1px solid #d1d6db',
          }}
        >
          <AverageFace
            color={
              selectedFeedback === FeedbackOptions.Average
                ? '#FFB300'
                : '#B0B8C1'
            }
          />
        </IconButton>
        <IconButton
          disabled={isFeedbackSent}
          onClick={() => handleFeedBackClicked(FeedbackOptions.Unhappy)}
          sx={{
            width: '58px',
            height: '56px',
            backgroundColor: 'white',
            borderRadius: '16px',
            border: '1px solid #d1d6db',
          }}
        >
          <UnhappyFace
            color={
              selectedFeedback === FeedbackOptions.Unhappy
                ? '#C62828'
                : '#B0B8C1'
            }
          />
        </IconButton>
      </Stack>
      {showGoogleRateButton && <GoogleRateButton />}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={NoTransition}
        PaperProps={{
          sx: {
            height: '100vh',
          },
        }}
      >
        <Feedback
          transactionId={transaction?.transactionId}
          selectedFeedback={selectedFeedback}
          chargerName={transaction?.charger?.name}
          startTime={transaction?.startTime}
          endTime={transaction?.stopTime}
          location={`${transaction?.charger?.location?.name} on ${transaction?.charger?.location?.streetAddress} ${transaction?.charger?.location?.city},${transaction?.charger?.location?.stateOrRegion}, ${transaction?.charger?.location?.zipOrPostalCode}`}
          energyDelivered={transaction?.consumedEnergyKilowattHours}
          cost={transaction?.billedTotalAmount}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      </Dialog>
    </Box>
  );
};

export default FeedbackWithOptions;
