import moment from 'moment';

export const TAX_NUMBERS: TAX_TYPES = {
  GST: '760780494 RT0001',
  HST: '760780494 RT0001',
  QST: '1224001084 TQ0001',
};

export interface TAX_TYPES {
  GST?: string;
  HST?: string;
  QST?: string;
}

export const formatNumber = (input: number, decimalCount: number) => {
  try {
    return input.toFixed(decimalCount);
  } catch (e) {
    return '0.00';
  }
};

/**

Formats a given date string into a human-readable time format.
@param {string} dateString - The date string to be formatted.
@returns {string} The formatted time in "h:mm A" format (e.g., "2:30 PM").
*/

export const formatTime = (dateString: string) => {
  return moment(dateString).format('h:mm A');
};

export const sortTaxes = (taxes: TAX_TYPES) => {
  const entries = Object.entries(taxes);

  const sortedEntries = entries.sort(([keyA], [keyB]) =>
    keyA.localeCompare(keyB),
  );

  return Object.fromEntries(sortedEntries);
};
