import { ColorType } from '../../assets/colors/colors.g';

export const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: 'Inter',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '##B0B8C1',
        fontFamily: 'Inter',
      },
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};

export const useStyles = {
  paddingBottom8: {
    paddingBottom: '8px',
  },
  dropDownSelectedItem: {
    backgroundColor: '#F2F4F6',
    color: '#18A0D7',
    borderRadius: '0.25rem',
  },
  dropdownMenuItem: {
    minWidth: '110px',
    padding: 16,
    cursor: 'pointer',
    borderRadius: '4px',
    color: '#4e5968',
  },
  dropdownMenuItemHover: {
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropDownMenu: {
    backgroundColor: '#fff',
    position: 'absolute' as 'absolute',
    display: 'block',
    listStyleType: 'none',
    left: 0,
    marginTop: 16,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '30rem',
    overflow: 'auto',
    zIndex: 1,
  },
  labelM: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  displayBlock: {
    display: 'block',
  },
  paddingTop: {
    paddingTop: 8,
  },
  drodDownButton: {
    background: '#F2F4F6',
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    padding: '12px 8px 12px 16px',
    cursor: 'pointer',
    border: 0,
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  container: {
    padding: '24px 16px',
    background: '#FAFAFA',
    marginBottom: '90px',
  },
  Mb16: {
    marginBottom: '16px',
  },
  Mb8: {
    marginBottom: '8px',
  },
  PL8: {
    paddingLeft: '8px',
  },
  PR8: {
    paddingRight: '8px',
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '24px',
    color: '#202223',
    marginBottom: '8px',
  },
  cardInput: {
    borderRadius: 4,
    'box-sizing': 'border-box',
    height: 48,
    padding: '16px 16px',
    background: '#F2F4F6',
    border: '2px solid transparent',
  },
  errorLabel: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: ColorType.NEGATIVE,
  },
  inputWrapper: {
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
  },
  labelB2: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  Mb: {
    marginBottom: '16px',
  },

  errorBorder: {
    border: `2px solid ${ColorType.NEGATIVE}`,
  },

  labelPosition: {
    position: 'absolute',
    top: '50%',
    right: '14px',
    color: ColorType.NEGATIVE,
    transform: 'translate(0%, -50%)',
  },
  relative: {
    position: 'relative',
  },
};
