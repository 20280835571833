import { ColorPaletteObject } from '../../stores/types/theme.interface';

export enum ColorType {
  BRAND0 = '#E8F7FC',
  BRAND1 = '#BBE7F6',
  BRAND2 = '#18A0D7',
  BRAND3 = '#117DB8',
  DENIM = '#117DB8',
  DENIM_HOVER = '#065C91',
  DENIM_LIGHT = '#DFF3F7',
  DENIM_LIGHT_HOVER = '#CBF6FF',
  INK = '#1F2631',
  INK_HOVER = '#30363D',
  INK_LIGHT = '#F4F7F9',
  INK_LIGHT_HOVER = '#E7E8E9',
  WHITE = '#FFFFFF',
  BLACK = '#202223',
  GREY0 = '#F9FAFB',
  GREY1 = '#F2F4F6',
  GREY2 = '#E5E8EB',
  GREY3 = '#D1D6DB',
  GREY4 = '#B0B8C1',
  GREY5 = '#6B7684',
  GREY6 = '#4E5968',
  NEGATIVE0 = '#FFEFED',
  NEGATIVE = '#C62828',
  WARNING0 = '#FFFAF0',
  WARNING = '#FFB300',
  POSITIVE0 = '#F5FAF0',
  POSITIVE = '#47820A',
  ACCENT0 = '#EFF3FE',
  ACCENT1 = '#039BE5',
  ACCENT2 = '#0277BD',
  ACCENT_SUB = '#EBF8FF',
  WEB_BG = '#F5F6FA',
  PURPLE = '#7356BF',
  TEAL = '#00897B',
  TOGGLE_GREEN = '#4BD865',
  BLACK2 = '#000000',
}

export const defaultColorPalettes: ColorPaletteObject = {
  denim: {
    primary: '#117DB8',
    hover: '#065C91',
    light: '#DFF3F7',
    light_hover: '#CBF6FF',
  },
  ink: {
    primary: '#1F2631',
    hover: '#30363D',
    light: '#F4F7F9',
    light_hover: '#E7E8E9',
  },
};
