import { Grid } from '@mui/material';
import clsx from 'clsx';
import { receiptStyles } from './styles';
import { sortTaxes } from './utils';

interface IProps {
  receipt: any;
}
const ReceiptTaxes = ({ receipt }: IProps) => {
  const classes = receiptStyles();
  const readableTaxDescriptions = (taxCode: string, percentage: any) => {
    switch (taxCode) {
      case 'sales tax':
        return `Sales tax (${percentage}%)`;
      default:
        return `${taxCode} (${percentage}%)`;
    }
  };

  const formatNumber = (input: number, decimalCount: number) => {
    try {
      return input.toFixed(decimalCount);
    } catch (e) {
      return '0.00';
    }
  };

  return (
    <>
      {Object.entries(sortTaxes(receipt.taxRates)).map(([key, value]) => (
        <Grid container item className={classes.mt8}>
          <Grid container item direction='column' xs={9}>
            <Grid item>
              <p
                className={clsx(
                  classes.font14,
                  classes.lineHeight20,
                  classes.gray5,
                  classes.m0,
                )}
              >
                {readableTaxDescriptions(key, value)}
              </p>
            </Grid>
          </Grid>
          <Grid container item xs={3} justifyContent='flex-end'>
            <Grid item>
              <span
                className={clsx(
                  classes.font14,
                  classes.gray6,
                  classes.lineHeight20,
                  classes.m0,
                )}
              >{`$${formatNumber(receipt.taxedAmounts[key], 2)}`}</span>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ReceiptTaxes;
