import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ColorType } from '../../assets/colors/colors.g';

export const rfidStyle = makeStyles((theme: Theme) => ({
  h6: { ...theme.typography.h6 },
  h5: { ...theme.typography.h5 },
  subtitle1: { ...theme.typography.subtitle1 },
  body1: { ...theme.typography.body1, paddingTop: '8px', paddingBottom: '8px' },
  root: {
    maxWidth: '425px',
    position: 'fixed',
    bottom: 0,
    boxSizing: 'border-box',
    padding: '8px 20px 34px 20px',
    backgroundColor: '#fff',
  },
  button: {
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#E8F7FC',
    color: '#18A0D7',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#E8F7FC',
      boxShadow: 'none',
    },
    pointer: {
      cursor: 'pointer',
    },
    boxShadow: 'none',
  },
  orderButtonContainer: {
    backgroundColor: '#18A0D7',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#18A0D7',
      boxShadow: 'none',
    },
  },
  activateButtonContainer: {
    backgroundColor: '#E8F7FC',
    color: '#18A0D7',
    '&:hover': {
      backgroundColor: '#E8F7FC',
      boxShadow: 'none',
    },
  },
  deleteButtonContainer: {
    background: '#FFFFFF',
    color: ColorType.NEGATIVE,
    border: '1px solid #D1D6DB',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
    },
  },
  orderHeading: {
    fontSize: 24,
    lineHeight: '32px',
    justifyContent: 'center',
    color: '#18A0D7',
    display: 'flex',
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  orderMessage: {
    fontSize: 16,
    lineHeight: '24px',
    display: 'flex',
    textAlign: 'center',
    marginBottom: '456px',
  },
  firstCardMessage: {
    fontSize: 24,
    lineHeight: '32px',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 600,
    fontFamily: 'Inter',
    paddingBottom: '8px',
  },
  addressConfirmationText: {
    fontWeight: 400,
    display: 'flex',
    textAlign: 'center',
    marginBottom: '32px',
    fontFamily: 'Inter',
  },
  FAQ: {
    marginBottom: '15px',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textHeader: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 600,
  },
  cancelBtn: {
    marginLeft: '8px',
    backgroundColor: '#F2F4F6',
    color: '#4E5968',
    '&:hover': {
      backgroundColor: '#F2F4F6',
      boxShadow: 'none',
    },
  },
  container: {
    marginBottom: '1rem',
    marginTop: '1rem',
    padding: '12px',
  },
  wrapper: {
    display: 'flex',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  verified: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: ColorType.POSITIVE,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#202223',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#6B7684',
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 18,
    color: '#202223',
    lineHeight: '26px',
    fontWeight: 600,
    marginBottom: 12,
    marginTop: 12,
  },
  itemWrapper: {
    borderRadius: '12px',
    border: '1px solid #E5E8EB',
  },
  itemIcon: {
    alignSelf: 'baseline',
    marginTop: 6,
    minWidth: 32,
  },
  forwardIcon: {
    fontSize: 18,
    color: '#B0B8C1',
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formWrapper: {
    padding: '8px 20px',
  },
  formInput: {
    marginTop: 20,
    marginBottom: 10,
  },
  paddingTop: {
    paddingTop: 8,
  },
  dropDownMenu: {
    backgroundColor: '#fff',
    position: 'absolute' as 'absolute',
    display: 'block',
    listStyleType: 'none',
    left: 0,
    marginTop: 16,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '30rem',
    overflow: 'auto',
    zIndex: 1,
  },
  dropdownMenuItem: {
    minWidth: '110px',
    padding: 16,
    cursor: 'pointer',
    borderRadius: '4px',
    color: '#4e5968',
  },
  dropdownMenuItemHover: {
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
  },
  drodDownButton: {
    background: '#F2F4F6',
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    padding: '12px 8px 12px 16px',
    cursor: 'pointer',
    border: 0,
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  dropDownSelectedItem: {
    backgroundColor: '#F2F4F6',
    color: '#18A0D7',
    borderRadius: '0.25rem',
  },
  width110: {
    width: '110px',
  },
  inputWrapper: {
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
  },
  activeCardInputWrapper: {
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
      backgroundColor: '#F2F4F6',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
  },
  paddingBottom8: {
    paddingBottom: '8px',
  },
  labelB2: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  labelM: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  displayBlock: {
    display: 'block',
  },
  errorMessage: {
    color: ColorType.NEGATIVE,
    paddingTop: 8,
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '24px',
  },
  wHalf: {
    width: '50%',
  },
}));
