import { createStyles } from '@mui/styles';
import { ColorType } from '../../../../assets/colors/colors.g';

export const DetailsStyles = createStyles({
  root: {},
  itemContainer: {
    width: '100%',
    justifyContent: 'space-around',
    padding: '16px 0px',
  },
  showDetailsButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  collapseDetails: {
    width: '100%',
  },
  showDetails: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px',
    padding: '6px 0px',
  },
  listWrapper: {
    padding: 16,
    border: '1px solid #E5E8EB',
    borderRadius: 12,
  },
  list: {
    padding: '6px 0px',
  },
  listHead: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#202223',
    fontFamily: 'Inter',
  },
  listText: {
    fontSize: 14,
    fontFamily: 'Inter',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#6B7684;',
  },
  pt16: {
    paddingTop: 10,
  },
  weight500: {
    fontWeight: 500,
  },
  mt12: {
    marginTop: 12,
  },
  mt10: {
    marginTop: 10,
  },
  mt16: {
    marginTop: 16,
  },
  green: {
    color: ColorType.POSITIVE,
  },
  borderBottom2: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: '#E5E8EB',
  },
});
