import React, { useContext } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CrediCardIcon } from '../ui-components/credit-card-icon.component';
import { map } from 'lodash';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import {
  AddCircleRounded,
  ArrowForwardIos as ArrowForwardIosIcon,
  CheckCircleRounded,
} from '@mui/icons-material';
import { CardStatus, PaymentMode } from './types/payment-mode.enum';
import { PaymentContext } from '../../hooks/usePayment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { sortedPaymentMethod } from '../../hooks/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserPreference } from '../../hooks/useUserPreference';
import CustomButton from '../react-native-material-wrapper/button.component';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { ColorType } from '../../assets/colors/colors.g';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 16px',
    overflow: 'auto',
    boxSizing: 'border-box',
  },
  listItem: {
    padding: '0px',
    marginBottom: 8,
  },
  balanceTitle: {
    fontSize: '18px',
    lineHeight: '26px',
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#202223',
    padding: '0px',
    marginBottom: 8,
  },
  balanceAmount: {
    fontSize: '32px',
    lineHeight: '42px',
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#202223',
    marginBottom: 8,
  },
  fontMedium: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '20px',
    color: '#6B7684',
  },
  description: {
    marginBottom: 8,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  add: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  itemText: {
    fontSize: 14,
    maxHeight: '40px',
    padding: 0,
  },
  paddgin8: {
    padding: '8px 0px',
  },
  paddgin16: {
    padding: '16px 0px',
  },
  divider: {
    marginLeft: 54,
  },
  itemIcon: {
    display: 'flex',
    padding: '0px 4px',
    justifyContent: 'center',
    minWidth: '0px',
    width: '32px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cardStatus: {
    display: 'initial',
    marginLeft: '8px',
  },
  primary: {
    color: '#6B7684',
  },
  expired: {
    color: '#C62828',
  },
  expiring: {
    color: '#FF8F00',
  },
  errorContainer: {
    height: '72px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px 20px',
    background: ColorType.NEGATIVE,
  },
  errorMessage: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#FFFFFF',
  },
  margin0: {
    margin: 0,
  },
  margin16: {
    margin: '16px 0px',
  },
  paymentSuccessContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px',
    background: '#F5FAF0',
    alignItems: 'center',
  },
  paymentSuccessMessage: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#202223',
  },
  gap16: {
    gap: '16px',
  },
  minWidth: {
    minWidth: '0px',
  },
  btcBtnWrapper: {
    padding: '16px',
    boxSizing: 'border-box',
    position: 'sticky',
    bottom: '0px',
    maxWidth: '425px',
    zIndex: 1,
    '& button': {
      textTransform: 'none',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      width: '100%',
      display: 'flex',
      padding: '12px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      color: '#FFFFF',
    },
  },
}));

const PaymentDetails = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useSelector(getTheme);
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const paymnetConsumer = useContext(PaymentContext);
  const {
    payments,
    balance,
    handlePaymentView,
    handleSelectPayment,
    errorMsg,
    successMsg,
  } = paymnetConsumer;

  const billedOnDayOf = moment(balance?.billedOnDayOf);
  const expiryTime = billedOnDayOf.add(1, 'month').startOf('month');

  const formatExpireTime = moment(expiryTime).format('MM/DD/YYYY');

  const { userIsNotSelectedForPayGo } = useUserPreference();

  const handleEditPayment = (payment) => {
    handleSelectPayment(payment);
    handlePaymentView(PaymentMode.PAYMENT_ADD, true);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleBackToCharger = () => {
    navigate(`/${chargerName}`);
  };

  const outStandingBalnce = (balance?.amount ? balance?.amount : 0)?.toFixed(2);

  const getExpiryStatus = (expireStatus: string, expireData = '') => (
    <Typography
      className={clsx({
        [classes.fontMedium]: true,
        [classes.cardStatus]: true,
        [classes.expired]: expireStatus === CardStatus.EXPIRED,
        [classes.expiring]: expireStatus === CardStatus.EXPIRING,
      })}
    >
      {expireStatus === CardStatus.EXPIRED && i18n.t('expired')}
      {expireStatus === CardStatus.EXPIRING &&
        `${i18n.t('expires')} ${expireData}`}
    </Typography>
  );

  return (
    <>
      <BackButtonHeader
        title={i18n.t('payments')}
        onBackArrowClicked={handleBackButton}
      />
      {/* Show payment added / edited success msg */}
      {successMsg && (
        <Grid container className={classes.paymentSuccessContainer}>
          <Grid item xs={1}>
            <CheckCircleRounded
              style={{
                color: ColorType.POSITIVE,
                width: '24px',
                height: '24px',
                display: 'flex',
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.paymentSuccessMessage}>
              {i18n.t(successMsg)}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid className={classes.container}>
        <Typography className={classes.balanceTitle}>
          {i18n.t('outstanding_balance')}
        </Typography>
        <Typography className={classes.balanceAmount}>
          {`$${outStandingBalnce}`}
        </Typography>
        <Typography className={clsx(classes.fontMedium, classes.description)}>
          {`${i18n.t('next_payment_scheduled_for')} ${formatExpireTime}`}
        </Typography>
        <List>
          <ListItem className={clsx([classes.itemText])}>
            <ListItemText
              disableTypography
              className={clsx([classes.balanceTitle, classes.margin0])}
              primary={i18n.t('payment_methods')}
            />
          </ListItem>
          {map(sortedPaymentMethod(payments?.payload || []), (payment) => (
            <>
              <ListItem
                key={payment.sourceId}
                className={clsx(
                  classes.itemText,
                  classes.margin16,
                  classes.cursorPointer,
                  classes.gap16,
                )}
                onClick={() => handleEditPayment(payment)}
              >
                <ListItemIcon className={classes.minWidth}>
                  <CrediCardIcon brand={`${payment.brand}`} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={clsx([classes.capitalize, classes.margin0])}
                  primary={`${i18n.t('ending_in')} ${payment.last4}`}
                  secondary={
                    <>
                      {payment.isDefault && (
                        <Typography
                          className={clsx(
                            classes.fontMedium,
                            classes.cardStatus,
                            classes.primary,
                          )}
                        >
                          {i18n.t('primary')}
                        </Typography>
                      )}
                      {getExpiryStatus(
                        payment.expiredStatus,
                        payment?.expiredDate,
                      )}
                    </>
                  }
                />

                <ListItemSecondaryAction>
                  <IconButton
                    edge='end'
                    aria-label='payment_view'
                    onClick={() => handleEditPayment(payment)}
                  >
                    <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider className={classes.divider} />
            </>
          ))}

          <ListItem
            button
            onClick={() => handlePaymentView(PaymentMode.PAYMENT_ADD)}
            key={'payment_view'}
            className={clsx([
              classes.itemText,
              classes.margin16,
              classes.gap16,
            ])}
          >
            <ListItemIcon className={classes.itemIcon}>
              <AddCircleRounded style={{ color: '#4E5968' }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={classes.add}
              primary={i18n.t('payment_method_add')}
            />
          </ListItem>
        </List>
      </Grid>
      <div className={classes.btcBtnWrapper}>
        <CustomButton
          variant='contained'
          style={{
            backgroundColor: theme.colorPalettes.primary,
          }}
          onClick={handleBackToCharger}
        >
          {i18n.t('back_to_the_charger')}
        </CustomButton>
      </div>
    </>
  );
};

export default PaymentDetails;
