import { makeStyles } from '@mui/styles';
import { ColorType } from '../../../assets/colors/colors.g';

export const emailStyles = makeStyles({
  formWrapper: {
    padding: '16px 20px',
    maxWidth: 425,
    '& .react-tel-input .arrow': {
      // width: 10,
      // height: 15,
    },
    '& .react-tel-input': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    '& .react-tel-input .form-control': {
      marginLeft: 8,
      width: '100%',
      height: 48,
      padding: '12px 16px',
      border: '2px solid transparent',
      background: '#F2F4F6',
    },
    '& .react-tel-input .form-control:focus': {
      border: '2px solid #000000',
    },
    '& .react-tel-input .flag-dropdown': {
      position: 'relative',
      border: '2px solid transparent',
      borderRadius: '4px',
      // background: '#F2F4F6',
    },
    '& .react-tel-input .selected-flag': {
      width: 44,
      border: '2px solid transparent',
      background: '#F2F4F6',
    },
    '& .react-tel-input .flag-dropdown .open': {
      width: 44,
      border: '2px solid #000000',
      borderRadius: '4px',
      boxSizing: 'border-box',
      background: '#F2F4F6',
    },
  },
  formInput: {
    marginTop: 20,
    marginBottom: 10,
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '32px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#202223',
  },
  inputLabel: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
  },
  helpText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#4E5968',
  },
  marginTop8: {
    marginTop: '8px',
  },
  errorMessage: {
    color: ColorType.NEGATIVE,
  },
  verifiedMessage: {
    color: ColorType.POSITIVE,
  },
  notVerifiedMessage: {
    color: '#FF8F00',
  },
  displayRelative: {
    position: 'relative',
  },

  labelPosition: {
    position: 'absolute',
    top: '25%',
    right: '14px',
    transform: 'translate(0%, -50%)',
  },
  inputWrapper: {
    '& .MuiOutlinedInput-root': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000000', // change to your desired color
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  formControl: {
    '& .MuiSelect-selectMenu': {
      boxSizing: 'border-box',
      padding: '16px 8px',
      height: '48px',
      width: '82px',
    },
  },
});
