import './loading-dots.css';
import { useSelector } from 'react-redux';
import { getTheme } from '../../../stores/selectors/theme.selector';
import { makeStyles } from '@mui/styles';

interface InputProps {
  size?: string;
  className?: string;
}

const useStyles = makeStyles(() => ({
  dotWrapper: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '50%',
    alignItems: 'center',
    width: '64px',
  },
  dots: {
    display: 'flex',
    margin: '0px auto',
    width: '8px',
    aspectRatio: '1/1',
    borderRadius: '9999px',
  },
}));

export const LoadingDots = ({ size = '', className }: InputProps) => {
  const theme = useSelector(getTheme);
  const classes = useStyles();

  return (
    <div className={`${classes.dotWrapper} ${className}`}>
      <div
        className={`${classes.dots} loading-dots`}
        style={{
          ['--loadingDotColor1' as any]: theme?.brand_2,
          ['--loadingDotColor2' as any]: theme?.brand_1,
        }}
      />
    </div>
  );
};
