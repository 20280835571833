import { Grid } from '@mui/material';
import clsx from 'clsx';
import ReceiptIcon from '../../assets/icons/receipt/Receipt.svg';
import TooltipIcon from '../../assets/icons/receipt/Tooltip.svg';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { readableDuration } from '../../util/date-utils';
import moment from 'moment';
import ReceiptTaxes from './ReceiptTaxes.component';
import { receiptStyles } from './styles';
import { formatNumber, formatTime } from './utils';

interface IProps {
  receipt: any;
}
const CostDetails = ({ receipt }: IProps) => {
  const { i18n } = useTranslation();
  const classes = receiptStyles();
  const checkIfBillingTypeEffectiveChargingTime = () => {
    return (
      !receipt.freeCharger &&
      receipt.chargingLineItems &&
      receipt.chargingLineItems[0]['unitPricingType'] ===
        'BILLED_BY_EFFECTIVE_CHARGING_TIME'
    );
  };
  // August 10,
  const formatDateMonthOnly = (dateString: string) => {
    return moment(dateString).locale(i18n.language).format('MMMM DD,');
  };

  const readableLineItemDate = (startDate: string, endDate: string) => {
    const readableStartDate = formatDateMonthOnly(startDate);
    const readableEndDate = formatDateMonthOnly(endDate);

    const readableStartTime = formatTime(startDate);
    const readableEndTime = formatTime(endDate);

    // August 10, 2022
    const formatDate = (dateString: string) => {
      return moment(dateString).locale(i18n.language).format('MMMM DD, YYYY');
    };

    const receiptRadableStartDate = formatDate(receipt.startTime);
    const receiptReadableEndDate = formatDate(receipt.stopTime);
    let result = '';
    // when the start and end dates are not the same, we will show the Month
    if (receiptRadableStartDate !== receiptReadableEndDate) {
      if (readableStartDate === readableEndDate) {
        result =
          readableStartDate + ' ' + readableStartTime + ' - ' + readableEndTime;
      } else {
        result = `${readableStartDate} ${readableStartTime} - ${readableEndDate} ${readableEndTime}`;
      }
    } else {
      // just show the time when the start and end date are the same
      result = `${readableStartTime} - ${readableEndTime}`;
    }
    return result;
  };
  const readableBillingType = (billingType: string) => {
    switch (billingType) {
      case 'BILLED_BY_TIME_PLUGGED_IN':
        return `hr ${i18n.t('receipt_plugged_in')}`;
      case 'BILLED_BY_EFFECTIVE_CHARGING_TIME':
        return `hr ${i18n.t('receipt_charging')}`;
      case 'BILLED_BY_KWH':
        return 'kWh';
      case 'IDLE_RATE':
        return 'min';
      default:
        return `hr ${i18n.t('receipt_charging')}`;
    }
  };
  const readableLineItemPrice = (lineItem: any) => {
    if (lineItem.unitPricingType === 'FREE') {
      return '@ Free';
    }
    // @ $1.00/['hr charging', 'hr plugged in', 'kWh']
    return `@ $${formatNumber(lineItem.unitPrice, 2)}/${readableBillingType(
      lineItem.unitPricingType,
    )}`;
  };

  const readableLineItemDescription = (lineItem: any) => {
    if (
      lineItem.unitPricingType === 'BILLED_BY_KWH' ||
      lineItem.unitPricingType === 'FREE'
    ) {
      // eg: 15.5123 kWh @ $0.50/kWh or 15.5123 kWh @ Free
      return `${formatNumber(
        lineItem.energyDelivered,
        4,
      )} kWh ${readableLineItemPrice(lineItem)}`;
    }

    // eg: X hr X min @ $3.00/['hr charging', 'hr plugged in'] or X hr X min @ Free
    return `${readableDuration(
      moment
        .duration(moment(lineItem.stopTime).diff(moment(lineItem.startTime)))
        .asSeconds(),
    )} ${readableLineItemPrice(lineItem)}`;
  };

  const renderLineItem = (lineItem: any) => {
    return (
      <Grid container item className={classes.mt8}>
        <Grid container item direction='column' xs={9}>
          <Grid item className={clsx(classes.mt4)}>
            <p
              className={clsx(
                classes.font14,
                classes.lineHeight20,
                classes.m0,
                classes.gray5,
              )}
            >
              {readableLineItemDescription(lineItem)}
            </p>
          </Grid>
          <Grid item className={clsx(classes.mt2)}>
            <p
              className={clsx(
                classes.font14,
                classes.lineHeight20,
                classes.m0,
                classes.gray5,
              )}
            >
              {readableLineItemDate(lineItem.startTime, lineItem.stopTime)}
            </p>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.mt4)}
          container
          item
          xs={3}
          justifyContent='flex-end'
        >
          <p
            className={clsx(
              classes.font14,
              classes.lineHeight20,
              classes.m0,
              classes.gray6,
            )}
          >{`$${formatNumber(lineItem.totalPrice, 2)}`}</p>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {/* separator */}
      <Grid
        container
        className={clsx(classes.borderBottom8, classes.pt20)}
      ></Grid>

      <Grid
        className={clsx(
          classes.fontInter,
          classes.darkGray,
          classes.pt16,
          classes.px16,
        )}
        container
      >
        <Grid container alignItems='center' item>
          <Grid item xs={1}>
            <img src={ReceiptIcon} alt='' className={classes.displayBlock} />
          </Grid>
          <Grid item xs={11}>
            <span className={classes.weight600}>
              {i18n.t('receipt_cost_detail')}
            </span>
          </Grid>
        </Grid>

        <Grid
          container
          item
          className={clsx(classes.border, classes.p16, classes.mt16)}
        >
          {/* Base fee is not yet in the backend
                        <Grid container item>
                            <Grid item xs={8}>
                                <span className={classes.weight500}>Base fee</span>
                            </Grid>
                            <Grid container item xs={4} justify="flex-end">
                                <span className={clsx(classes.weight500)}>$1.00</span>
                            </Grid>
                        </Grid>
                        */}

          <Grid container item>
            <Grid container item xs={8}>
              <Grid item>
                <span className={classes.weight500}>{`${i18n.t(
                  'charging',
                )}`}</span>
              </Grid>
              {checkIfBillingTypeEffectiveChargingTime() && (
                <Grid item className={classes.pl4}>
                  <Tippy
                    content={
                      <span>
                        Charging time may be shorter than plugged in time and is
                        adjusted based on the actual speed of charge
                      </span>
                    }
                    arrow
                    allowHTML
                    placement='top-start'
                    interactive
                    maxWidth={300}
                    offset={[-92, 10]}
                    className={clsx(
                      classes.font14,
                      classes.bgDarkGray,
                      classes.white,
                      classes.py12px16,
                      classes.roundBorder,
                      classes.weight500,
                    )}
                  >
                    <img
                      src={TooltipIcon}
                      alt=''
                      className={clsx(classes.displayBlock, classes.pointer)}
                    />
                  </Tippy>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <span className={clsx(classes.weight500)}>{`$${
                receipt.chargingLineItems &&
                formatNumber(
                  receipt.chargingLineItems
                    .map((lineItem) => lineItem.totalPrice)
                    .reduce((a, b) => a + b, 0),
                  2,
                )
              }`}</span>
            </Grid>
          </Grid>

          {receipt.chargingLineItems &&
            // prevent displaying lineItems if the unitPricingType only contains FREE
            receipt.chargingLineItems.some(
              (lineItem) => lineItem.unitPricingType !== 'FREE',
            ) &&
            receipt.chargingLineItems.map((lineItem) =>
              renderLineItem(lineItem),
            )}
          {receipt.taxRates && <ReceiptTaxes receipt={receipt} />}
          {receipt.idleLineItems && (
            <Grid container item className={classes.mt16}>
              <Grid item xs={8}>
                <span className={classes.weight500}>
                  {i18n.t('receipt_idling')}
                </span>
              </Grid>
              <Grid container item xs={4} justifyContent='flex-end'>
                <span className={clsx(classes.weight500)}>{`$${
                  receipt.idleLineItems &&
                  formatNumber(
                    receipt.idleLineItems
                      .map((lineItem) => lineItem.totalPrice)
                      .reduce((a, b) => a + b, 0),
                    2,
                  )
                }`}</span>
              </Grid>
            </Grid>
          )}

          {receipt.idleLineItems &&
            receipt.idleLineItems.map((lineItem) => {
              return (
                <Grid container item className={classes.mt4}>
                  <Grid container item direction='column' xs={8}>
                    <Grid item className={clsx(classes.mt4)}>
                      <span
                        className={clsx(classes.font14, classes.gray5)}
                      >{`${readableDuration(
                        lineItem.duration,
                      )} @ $${formatNumber(
                        lineItem.unitPrice,
                        2,
                      )}/${readableBillingType(
                        lineItem.unitPricingType,
                      )}`}</span>
                    </Grid>
                    <Grid item className={clsx(classes.mt2)}>
                      <span className={clsx(classes.font14, classes.gray5)}>
                        {readableLineItemDate(
                          lineItem.startTime,
                          lineItem.stopTime,
                        )}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    className={clsx(classes.mt4)}
                    container
                    item
                    xs={4}
                    justifyContent='flex-end'
                  >
                    <span
                      className={clsx(classes.font14, classes.gray6)}
                    >{`$${formatNumber(lineItem.totalPrice, 2)}`}</span>
                  </Grid>
                </Grid>
              );
            })}
          <Grid
            container
            item
            className={clsx(classes.borderBottom2, classes.mt16)}
          ></Grid>

          <Grid container item className={classes.pt16}>
            <Grid item xs={8}>
              <span className={classes.weight500}>
                {i18n.t('receipt_subtotal')}
              </span>
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <span className={clsx(classes.weight500)}>{`$${formatNumber(
                receipt.subTotal,
                2,
              )}`}</span>
            </Grid>
          </Grid>
          {receipt.discountPercentage > 0 && (
            <Grid container item className={classes.mt12}>
              <Grid item xs={8}>
                <span className={classes.weight500}>
                  {i18n.t('receipt_discount')}{' '}
                  {`(${
                    receipt.discountPercentage === 100
                      ? i18n.t('free')
                      : receipt.discountPercentage + '%'
                  })`}
                </span>
              </Grid>
              <Grid container item xs={4} justifyContent='flex-end'>
                <span
                  className={clsx(classes.weight500, classes.green)}
                >{`- $${formatNumber(
                  receipt.subTotal - receipt.total,
                  2,
                )}`}</span>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            item
            className={clsx(classes.borderBottom2, classes.mt16)}
          ></Grid>

          <Grid container item className={classes.pt16}>
            <Grid item xs={8}>
              <span className={classes.weight500}>{i18n.t('total')}</span>
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <span className={clsx(classes.weight500)}>{`$${formatNumber(
                receipt.total,
                2,
              )}`}</span>
            </Grid>
          </Grid>

          {receipt?.amountUnpaid > 0 && (
            <Grid container item className={classes.pt16}>
              <Grid item xs={8}>
                <span className={classes.weight500}>
                  {i18n.t('amount_paid')}
                </span>
              </Grid>
              <Grid container item xs={4} justifyContent='flex-end'>
                <span className={clsx(classes.weight500)}>{`$${formatNumber(
                  receipt.amountPaid,
                  2,
                )}`}</span>
              </Grid>
            </Grid>
          )}

          <Grid container item className={classes.pt16}>
            <Grid item xs={8}>
              <span className={classes.weight500}>{i18n.t('currency')}</span>
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <span className={clsx(classes.weight500)}>
                {receipt.currency}
              </span>
            </Grid>
          </Grid>

          {receipt?.amountUnpaid > 0 && (
            <>
              <Grid container item className={classes.pt16}>
                <Grid item>
                  <span className={clsx(classes.font14, classes.gray)}>
                    {i18n.t('partial_payment_desc')}
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={clsx(classes.borderBottom2, classes.mt16)}
              ></Grid>
              <Grid container item className={classes.pt16}>
                <Grid item xs={8}>
                  <span
                    className={clsx(classes.weight500, classes.alertsNegative2)}
                  >
                    {i18n.t('amount_unpaid')}
                  </span>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <span
                    className={clsx(classes.weight500, classes.alertsNegative2)}
                  >{`$${formatNumber(receipt.amountUnpaid, 2)}`}</span>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CostDetails;
