import { Grid } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { ColorType } from '../../assets/colors/colors.g';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '425px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: '#fff',
    boxSizing: 'border-box',
    '& button': {
      width: '100% !important',
    },
  },
  buttonWrapper: {
    padding: '20px 16px',
  },
  buttonStyles: {
    color: '#ffffff',
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
  },
}));

const DeleteButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    width: '100%',
    height: '48px',
    padding: '6px 12px',
    lineHeight: 1.5,
    color: ColorType.NEGATIVE,
    border: '1px solid #D1D6DB',
    backgroundColor: '#FFFFFF',

    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFFFFF',
    },
  },
})(Button);

const CancelButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    width: '100%',
    height: '48px',
    padding: '6px 12px',
    lineHeight: 1.5,
    color: '#4E5968',
    backgroundColor: '#F2F4F6',
    '&:hover': {
      backgroundColor: '#E5E8EB',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F2F4F6',
    },
  },
})(Button);

interface IProps extends ButtonProps {
  onDelete: () => void;
  onCancel: () => void;
  loading: boolean;
  isDisabled: boolean;
}

const SessionButton = ({ onDelete, onCancel, loading, isDisabled }: IProps) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid container className={classes.buttonWrapper} spacing={1}>
        <Grid item xs={6}>
          <DeleteButton
            variant='outlined'
            color='error'
            className={classes.buttonStyles}
            onClick={onDelete}
            disabled={isDisabled}
          >
            {loading ? <LoadingDots /> : i18n.t('delete')}
          </DeleteButton>
        </Grid>

        <Grid item xs={6}>
          <CancelButton
            variant='contained'
            className={classes.buttonStyles}
            onClick={onCancel}
          >
            {i18n.t('cancel')}
          </CancelButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SessionButton;
