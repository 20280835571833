import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { ConnectorStatus } from '../../../types/connector-status.enum';
import { ConnectorV2 } from '../dto/charger.dto';
import { ColorType } from '../../../assets/colors/colors.g';

interface Props {
  connector: ConnectorV2;
  size?: number;
  underMaintenance?: boolean;
  showTechnicianIcon?: boolean;
  hasMySession?: boolean;
}

export const ChargerConnectorStatus = ({
  connector,
  size = 14,
  underMaintenance = false,
  hasMySession = false,
  showTechnicianIcon = false,
}: Props) => {
  const { i18n } = useTranslation();

  const getStatusInfo = () => {
    let statusText = '';
    let backgroundColor = '';
    let color = '';
    let status;
    // maintenance flag: driver didn't enable technicain view
    if (underMaintenance && !showTechnicianIcon && !hasMySession) {
      return {
        statusText: i18n.t('under_maintenance'),
        backgroundColor: '#E5E8EB',
        color: '#4E5968',
      };
    }
    if (
      underMaintenance &&
      !showTechnicianIcon &&
      connector.status?.toLowerCase() !== ConnectorStatus.SESSION.toLowerCase()
    ) {
      // display status
      status = ConnectorStatus.UNKNOWN;
    } else {
      // display real status
      status = connector.status;
    }

    switch (status.toLowerCase()) {
      case ConnectorStatus.SESSION.toLowerCase():
        statusText = hasMySession ? i18n.t('charging') : i18n.t('inuse');
        backgroundColor = ColorType.DENIM_HOVER;
        color = ColorType.WHITE;
        break;
      case ConnectorStatus.AVAILABLE.toLowerCase():
        statusText = i18n.t('available');
        backgroundColor = ColorType.POSITIVE;
        color = ColorType.WHITE;
        break;
      case ConnectorStatus.UNKNOWN.toLowerCase():
      case ConnectorStatus.UNAVAILABLE.toLowerCase():
        statusText = i18n.t('out_of_order');
        backgroundColor = ColorType.NEGATIVE;
        color = ColorType.WHITE;
        break;
      default:
        statusText = i18n.t(status);
        backgroundColor = '#F44336';
        color = ColorType.WHITE;
    }

    return {
      statusText,
      backgroundColor,
      color,
    };
  };

  return (
    <>
      {getStatusInfo() && (
        <Chip
          style={{
            backgroundColor: getStatusInfo().backgroundColor,
            color: getStatusInfo().color,
            fontSize: size,
            fontWeight: 500,
            fontFamily: 'Inter',
          }}
          size='small'
          label={getStatusInfo().statusText}
        />
      )}
    </>
  );
};
