import CustomButton from '../react-native-material-wrapper/button.component';
import { Box, Grid, Typography } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import { authStyles } from './authStyles';
import { useTranslation } from 'react-i18next';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import ChargerIcon from '../../assets/icons/charger/Charger.svg';
import LoginIcon from '../../assets/icons/receipt/Login.svg';
import PlayIcon from '../../assets/icons/charger/Play.svg';
import SignInSignUpButtons from '../ui-components/sign-in-sign-up-buttons.component';
import { loginInfo } from '../../assets/icons/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useAuth } from '../../hooks/useAuth';
import { useUserPreference } from '../../hooks/useUserPreference';
import {
  FetchChargerRequest,
  useFetchChargerDetailQuery,
} from '../../services/chargers.api';
import Bolt from '../ui-components/bolt.component';
import HaveQuestions from './have-questions.component';
import { ColorType } from '../../assets/colors/colors.g';

const LoginInfo = () => {
  const classes = authStyles();
  const { i18n } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');
  const { allowDriverImprovements } = useUserPreference();

  const theme = useSelector(getTheme);
  const { data: chargerDetail, isSuccess } = useFetchChargerDetailQuery(
    {
      chargerName: chargerName,
      isLoggedIn: auth.isLogined,
    } as FetchChargerRequest,
    {
      pollingInterval: 5000,
    },
  );
  const showLogin = () => {
    navigate(`/app/${window.domainName}/login?charger=${chargerName}`);
  };

  const showRegister = () => {
    navigate(`/app/${window.domainName}/register?charger=${chargerName}`);
  };

  const callSupport = () => {
    window.location.href = `tel:${chargerDetail.contactInfo}`;
  };

  const onClickBack = () => {
    navigate(-1);
  };
  return (
    <div
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: '#F2F4F6',
          width: '100%',
          maxWidth: 425,
          height: '100%',
          margin: 'auto',
        }}
      >
        <BackButtonHeader onBackArrowClicked={onClickBack} />
        <Grid
          container
          direction='column'
          style={{
            padding: '40px 24px',
            backgroundColor: '#FFF',
            height: '100%',
            alignItems: 'center',
            gap: '24px',
            alignSelf: 'stretch',
            display: allowDriverImprovements ? 'visible' : 'none',
          }}
        >
          <Box className={classes.pageContent}>
            <Typography
              variant='h3'
              style={{
                width: '100%',
                display: 'inline-block',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '600',
                lineHeight: '32px',
                textAlign: 'center',
              }}
            >
              {i18n.t('charging_instruction_header')}
            </Typography>
            <Box className={classes.pageContent}>
              <Box className={classes.contentBox}>
                <img
                  src={LoginIcon}
                  alt=''
                  className={classes.icon}
                  style={{ backgroundColor: theme.navigationSelectedColor }}
                />
                <Box className={classes.contentDetails}>
                  <Typography variant='h6' className={classes.contentHeader}>
                    {i18n.t('pointer_1')}
                  </Typography>
                  <Typography variant='h6' className={classes.contentItem}>
                    {i18n.t('pointer_1_details')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contentBox}>
                <img
                  src={ChargerIcon}
                  alt=''
                  className={classes.icon}
                  style={{ backgroundColor: theme.navigationSelectedColor }}
                />
                <Box className={classes.contentDetails}>
                  <Typography variant='h6' className={classes.contentHeader}>
                    {i18n.t('pointer_2')}
                  </Typography>
                  <Typography variant='h6' className={classes.contentItem}>
                    {i18n.t('pointer_2_details')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contentBox}>
                <img
                  src={PlayIcon}
                  alt=''
                  className={classes.icon}
                  style={{ backgroundColor: theme.navigationSelectedColor }}
                />
                <Box className={classes.contentDetails}>
                  <Typography variant='h6' className={classes.contentHeader}>
                    {i18n.t('pointer_3')}
                  </Typography>
                  <Typography variant='h6' className={classes.contentItem}>
                    {i18n.t('pointer_3_details')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contentBox}>
                <Bolt
                  width={38}
                  height={24}
                  color={'#FFF'}
                  style={{
                    backgroundColor: theme.navigationSelectedColor,
                    padding: '4px',
                    borderRadius: '32px',
                  }}
                />
                <Box className={classes.contentDetails}>
                  <Typography variant='h6' className={classes.contentHeader}>
                    {i18n.t('pointer_4')}
                  </Typography>
                  <Typography variant='h6' className={classes.contentItem}>
                    {i18n.t('pointer_4_details')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <HaveQuestions chargerDetail={chargerDetail} />
        </Grid>
        <Grid
          container
          direction='column'
          style={{
            padding: '24px',
            backgroundColor: '#FFF',
            height: '100%',
            display: allowDriverImprovements ? 'none' : 'visible',
          }}
        >
          <Box>
            <Grid container item justifyContent='center'>
              {loginInfo()}
            </Grid>
            <Typography
              variant='h3'
              style={{
                width: '100%',
                display: 'inline-block',
                marginTop: 40,
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '600',
                lineHeight: '32px',
                textAlign: 'center',
              }}
            >
              {i18n.t('login_to_start')}
            </Typography>
            <Typography
              variant='h6'
              style={{
                width: '100%',
                display: 'inline-block',
                marginTop: 8,
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                textAlign: 'center',
              }}
            >
              {i18n.t('login_info')}
            </Typography>
            <Box className={classes.callSupportBtn}>
              <Typography
                variant='h6'
                style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: '#4E5968',
                }}
              >
                {i18n.t('still_having_trouble')}
              </Typography>
              <CustomButton
                size='large'
                style={{
                  color: ColorType.DENIM,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  textTransform: 'inherit',
                  paddingLeft: '4px',
                }}
                onClick={callSupport}
              >
                {i18n.t('Call_support')}
              </CustomButton>
            </Box>
          </Box>
        </Grid>
        <SignInSignUpButtons
          showLogin={showLogin}
          showRegister={showRegister}
        />
      </div>
    </div>
  );
};

export default LoginInfo;
