import { Button, Grid, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { LoadingDots } from './loading-dots/loading-dots.component';
import { useTranslation } from 'react-i18next';
import { ColorType } from '../../assets/colors/colors.g';

const useStyles = makeStyles(() => ({
  absolute: {
    position: 'absolute',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
  },
  headerText: {
    color: '#6B7684',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
  },
}));

const CancelButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    fontFamily: 'Inter',
    padding: 0,
    height: 'auto',
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
})(Button);

const NextButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Inter',
    height: '48px',
    padding: '6px 12px',
    lineHeight: 1.5,
    color: '#4E5968',
    backgroundColor: '#F2F4F6',
    '&:hover': {
      backgroundColor: '#E5E8EB',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F2F4F6',
    },
  },
})(Button);

const SubmitCancelButtons = ({
  loading,
  submit,
  submitText,
  cancel = null,
  cancelText,
  theme,
  disabled = false,
}) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const getBgColor = () => {
    if (loading) {
      return theme.brand_0;
    }
    if (disabled) {
      return ColorType.GREY0;
    }
    return theme.colorPalettes.primary;
  };
  return (
    <Grid container className={classes.wrapper}>
      <div className={classes.btnWrapper}>
        <Typography className={classes.headerText}>
          {i18n.t('already_have_account_text')}
        </Typography>
        <CancelButton
          size='large'
          style={{ color: ColorType.DENIM }}
          onClick={cancel}
        >
          {cancelText}
        </CancelButton>
      </div>
      <NextButton
        variant='contained'
        size='large'
        style={{
          backgroundColor: getBgColor(),
          color: disabled ? ColorType.GREY3 : ColorType.WHITE,
          textTransform: 'inherit',
          padding: '16px 12px',
        }}
        onClick={submit}
        disabled={loading || disabled}
      >
        {submitText}
        {loading && <LoadingDots className={classes.absolute} />}
      </NextButton>
    </Grid>
  );
};

export default SubmitCancelButtons;
