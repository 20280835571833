import React, { useState } from 'react';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import CustomPhoneInput from '../react-native-material-wrapper/phone-input.component';
import SubmitCancelButtons from '../ui-components/submit-cancel-buttons.component';
import { authStyles } from '../login/authStyles';
import { SUB_ROUTES } from '../../types/routes.enum';
import { CardElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import CustomButton from '../react-native-material-wrapper/button.component';
import CustomCheckbox from '../ui-components/checkbox.component';
import clsx from 'clsx';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumberWithPlus } from '../../util/js-utility';
import { ColorType } from '../../assets/colors/colors.g';

interface IProps {
  handleInputChange: Function;
  isHomeId: boolean;
  registerUser: Function;
  loading: boolean;
  signIn: Function;
  registrationForm: any;
  validateForm: () => boolean;
  countryCode: string;
}
const RegisterForm = ({
  handleInputChange,
  isHomeId,
  registerUser,
  loading,
  signIn,
  registrationForm,
  validateForm,
  countryCode,
}: IProps) => {
  const theme = useSelector(getTheme);

  const { i18n } = useTranslation();
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const classes = authStyles();

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: 'Inter',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#818181',
        },
      },
      invalid: {
        color: 'red',
        iconColor: 'red',
      },
    },
  };

  const redirectTerms = () => {
    window.open('https://chargelab.co/driver-and-site-host-terms#driver');
  };
  const redirectPolicy = () => {
    window.open(
      'https://chargelab.co/driver-and-site-host-terms#privacy-policy',
    );
  };
  const isPhoneValid = isValidPhoneNumber(
    parsePhoneNumberWithPlus(registrationForm.phoneNumber),
  );

  const updatePhoneNumberBorder = () => {
    const phoneNumberInput = document.querySelector(
      '.react-tel-input .form-control:focus',
    ) as HTMLElement;

    if (phoneNumberInput) {
      phoneNumberInput.style.border = isPhoneValid
        ? '2px solid #000000'
        : '2px solid #FF0000';
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Grid container rowGap={3} columnSpacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.signUpHeader}>
            {window.domainName.toLowerCase() === SUB_ROUTES.IVY.toLowerCase()
              ? i18n.t('ivy_sign_up')
              : i18n.t('sign_up_to_start_charging')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            className={classes.inputWrapper}
            onChange={(e) => handleInputChange('firstName', e.target.value)}
            placeholder={`${i18n.t('first_name')} *`}
            variant='filled'
            InputProps={{ disableUnderline: true }}
            required
            autoFocus
            name='first_name'
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            className={classes.inputWrapper}
            onChange={(e) => handleInputChange('lastName', e.target.value)}
            placeholder={`${i18n.t('last_name')} *`}
            variant='filled'
            InputProps={{ disableUnderline: true }}
            required
            name='last_name'
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            className={classes.inputWrapper}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder={`${i18n.t('email')} *`}
            variant='filled'
            required
            InputProps={{ disableUnderline: true }}
            fullWidth={true}
            name='email'
          />
        </Grid>
        <Grid item xs={12} className={classes.phoneInput}>
          <CustomPhoneInput
            style={updatePhoneNumberBorder()}
            inputProps={{
              required: true,
            }}
            country={countryCode}
            inputStyle={{ height: 55, fontSize: 16 }}
            value={registrationForm?.phoneNumber}
            onChange={(value) => {
              handleInputChange('phoneNumber', value);
            }}
            onFocus={() => setIsPhoneFocused(true)}
            countryCodeEditable={false}
            placeholder={`${i18n.t('phone_number_placeholder')} *`}
          />
          {!isPhoneValid && isPhoneFocused && (
            <Typography className={classes.invalidPhoneMessage}>
              {i18n.t('phone_number_invalid')}
            </Typography>
          )}
        </Grid>
      </Grid>

      {isHomeId && (
        <div className={classes.isHomeId}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <CustomCheckbox
              color='primary'
              checked={registrationForm.isTermsAccepted}
              onChange={(event) =>
                handleInputChange('isTermsAccepted', event.target.checked)
              }
              name='accept_terms'
              style={{
                padding: '0px 9px',
              }}
            />
          }
          label={i18n.t('i_agree_to_the')}
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            },
          }}
        />
        <CustomButton
          className={classes.termsOfService}
          size='large'
          type='link'
          style={{
            color: ColorType.DENIM,
          }}
          onClick={redirectTerms}
        >
          {i18n.t('terms_of_service')}
        </CustomButton>
        <span className={classes.andText}>{i18n.t('and_text')}</span>
        <CustomButton
          size='large'
          type='link'
          className={classes.privacyPolicy}
          style={{
            color: ColorType.DENIM,
          }}
          onClick={redirectPolicy}
        >
          {i18n.t('privacy_policy')}
        </CustomButton>
      </Grid>
      <SubmitCancelButtons
        submit={registerUser}
        cancel={signIn}
        disabled={!validateForm()}
        submitText={i18n.t('next')}
        cancelText={i18n.t('login_instead_text')}
        loading={loading}
        theme={theme}
      />
      <Grid>
        <Typography
          className={clsx(classes.description, classes.size14)}
          style={{ margin: '8px 0px', lineHeight: '20px' }}
        >
          {i18n.t('sign_up_receive_stop_text_messages', {
            company: window.domainName,
          })}
        </Typography>
      </Grid>
    </div>
  );
};

export default RegisterForm;
