import { useEffect } from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import 'moment/locale/es';
import 'moment/locale/fr';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { paymentIcon } from '../../assets/icons/icons';
import BoltIcon from '../../assets/icons/receipt/Bolt2.svg';
import CalendarIcon from '../../assets/icons/receipt/Calendar.svg';
import ChargerIcon from '../../assets/icons/receipt/Charger_EV.svg';
import ListIcon from '../../assets/icons/receipt/List.svg';
import LocationIcon from '../../assets/icons/receipt/Location.svg';
import PayoutIcon from '../../assets/icons/receipt/Payout_money.svg';

import {
  useLazyFetchDriverSupportSettingsByChargerIdQuery,
  useLazyFetchReceiptSettingsByChargerNameQuery,
} from '../../services/env.api';
import { useFetchReceiptQuery } from '../../services/receipt.api';
import { styles } from '../../styles/styles.util';
import { readableDuration } from '../../util/date-utils';
import { Assets_URL } from '../../util/js-utility';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { CrediCardIcon } from '../ui-components/credit-card-icon.component';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import TaxNumbers from './TaxNumbers.component';
import { receiptStyles } from './styles';
import CostDetails from './CostDetails.component';
import { formatNumber, formatTime } from './utils';

export const Receipt = () => {
  const { i18n } = useTranslation();
  const classes = receiptStyles();
  const { receiptId } = useParams();
  const {
    data: receipt,
    isLoading,
    isFetching,
  } = useFetchReceiptQuery(receiptId);
  const [triggerFetchReceiptSettings, { data: receiptSettings }] =
    useLazyFetchReceiptSettingsByChargerNameQuery();
  const [triggerFetchDomainByChargerId, { data: domainByChargerId }] =
    useLazyFetchDriverSupportSettingsByChargerIdQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const transactionId = new URLSearchParams(location.search).get('transaction');
  const chargerName = new URLSearchParams(location.search).get('chargerName');
  // August 10,
  const formatDateMonthOnly = (dateString: string) => {
    return moment(dateString).locale(i18n.language).format('MMMM DD,');
  };

  // August 10, 2022
  const formatDate = (dateString: string) => {
    return moment(dateString).locale(i18n.language).format('MMMM DD, YYYY');
  };

  useEffect(() => {
    const element = document.getElementById(
      'favicon',
    ) as HTMLAnchorElement | null;
    if (receipt && element) {
      triggerFetchReceiptSettings(receipt.displayId);
      element.href = `${Assets_URL}?resourceId=logo-svg&chargerId=${receipt.displayId}`;
    }
    if (receipt?.displayId) {
      triggerFetchDomainByChargerId(receipt.displayId);
    }
  }, [receipt]);

  useEffect(() => {
    if (receiptSettings) document.title = receiptSettings.companyName;
  }, [receiptSettings]);

  const readableDate = () => {
    const readableStartDate = formatDate(receipt.startTime);
    const readableEndDate = formatDate(receipt.stopTime);

    const readableStartTime = formatTime(receipt.startTime);
    const readableEndTime = formatTime(receipt.stopTime);

    let result = '';
    if (readableStartDate === readableEndDate) {
      result =
        readableStartDate + ' ' + readableStartTime + ' - ' + readableEndTime;
    } else {
      result = `${readableStartDate} ${readableStartTime} -
            ${readableEndDate} ${readableEndTime}`;
    }
    return result;
  };

  const resolveChargerType = (chargerLevel: string) => {
    switch (chargerLevel) {
      case 'LEVEL_1':
      case 'LEVEL_2':
        return 'AC';
      case 'DC_FAST':
        return 'DC';
      default:
        return 'AC';
    }
  };

  const renderStatusNotification = (status: string, date: string) => {
    let spiel = '';
    switch (status) {
      case 'POWER_LOSS':
        spiel = `Power outage @ ${formatDateMonthOnly(date)} ${formatTime(
          date,
        )}`;
        break;
    }
    return spiel;
  };

  if (isLoading || isFetching) {
    return (
      <ViewWrapper>
        <div
          className={classes.flexCenter}
          style={{ ...styles.container, minHeight: '100vh' }}
        >
          <LoadingDots />
        </div>
      </ViewWrapper>
    );
  }

  return !receipt ? (
    <ViewWrapper>
      <div
        className={classes.flexCenter}
        style={{ ...styles.container, minHeight: '100vh' }}
      >
        <span className={clsx(classes.fontInter, classes.weight600)}>
          Receipt not found.
        </span>
      </div>
    </ViewWrapper>
  ) : (
    <ViewWrapper>
      <Grid className={clsx(classes.fontInter, classes.darkGray)} container>
        <BackButtonHeader
          title={i18n.t('receipt')}
          onBackArrowClicked={
            chargerName && transactionId
              ? () => navigate(`/${chargerName}`)
              : !chargerName && transactionId
              ? () => navigate(-1)
              : null
          }
          isReceipt
        />
        <Grid
          container
          alignItems='center'
          item
          style={{ paddingTop: '20px', marginLeft: 20 }}
        >
          <Grid item xs={1}>
            <img src={ListIcon} alt='' className={classes.displayBlock} />
          </Grid>
          <Grid item xs={7}>
            <span className={classes.weight600}>
              {i18n.t('receipt_charging_summary')}
            </span>
          </Grid>
        </Grid>

        <Grid
          container
          item
          className={clsx(classes.border, classes.m16, classes.p16)}
        >
          <Grid container item aria-label={i18n.t('date_time_session')}>
            <Grid item xs={1} justifyContent='center'>
              <img src={CalendarIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span
                  className={clsx(
                    classes.font14,
                    classes.weight600,
                    classes.preLine,
                  )}
                >
                  {readableDate()}
                </span>
              </Grid>
              {receipt.notifications &&
                receipt.notifications.length > 0 &&
                receipt.notifications[0].status === 'POWER_LOSS' && (
                  <Grid item className={clsx(classes.mt4)}>
                    <span className={clsx(classes.font14, classes.red)}>
                      {renderStatusNotification(
                        receipt.notifications[0].status,
                        receipt.notifications[0].statusTimestamp,
                      )}
                    </span>
                  </Grid>
                )}
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14)}>
                  {readableDuration(receipt.pluggedInTimeSeconds)}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('time')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('location')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={LocationIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {receipt.location.name}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14)}>
                  {receipt.location.streetAddress}
                </span>
              </Grid>
              <Grid item>
                <span
                  className={clsx(classes.font14)}
                >{`${receipt.location.city}, ${receipt.location.stateOrRegion}, ${receipt.location.zipOrPostalCode}`}</span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('receipt_location')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('charger_energy_speed')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={ChargerIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {receipt.displayId}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14)}>{`${formatNumber(
                  receipt.maxWattage,
                  1,
                )} kW ${resolveChargerType(receipt.chargerLevel)}`}</span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('receipt_charger')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('enerygy_added')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={BoltIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {formatNumber(receipt.energyConsumedKwh, 4)} kWh
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('receipt_energy_added')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.pt20}
            aria-label={i18n.t('session_cost')}
          >
            <Grid item xs={1} justifyContent='center'>
              <img src={PayoutIcon} alt='' />
            </Grid>
            <Grid container item direction='column' xs={11}>
              <Grid item>
                <span className={clsx(classes.font14, classes.weight600)}>
                  {!receipt.freeCharger && receipt.total > 0
                    ? `$${formatNumber(receipt.total, 2)}`
                    : i18n.t('free')}
                </span>
              </Grid>
              <Grid item className={clsx(classes.mt4)}>
                <span className={clsx(classes.font14, classes.gray)}>
                  {i18n.t('total')}
                </span>
              </Grid>
            </Grid>
          </Grid>

          {receipt.cardLast4 && (
            <Grid container item className={classes.pt20}>
              <Grid item xs={1} marginTop={'2px'}>
                {paymentIcon('#4e5968')}
              </Grid>
              <Grid container item direction='column' xs={11}>
                <Grid
                  container
                  alignItems='center'
                  gap={'4px'}
                  marginTop='-3px'
                  className={clsx(classes.flexCenter)}
                >
                  <CrediCardIcon brand={receipt.cardBrand || ''} />
                  <span className={clsx(classes.font14, classes.weight600)}>
                    {receipt?.cardLast4 || ''}
                  </span>
                </Grid>
                <Grid item className={clsx(classes.mt4)} marginLeft={'2px'}>
                  <span className={clsx(classes.font14, classes.gray)}>
                    {i18n.t('payment_method')}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!receipt.freeCharger && receipt.subTotal > 0 && (
        <CostDetails receipt={receipt} />
      )}
      <Grid
        className={clsx(
          classes.fontInter,
          classes.darkGray,
          classes.pt8,
          classes.px16,
        )}
        container
      >
        <Grid item>
          <span className={clsx(classes.font14, classes.gray6)}>{`${i18n.t(
            'receipt_price_set_by',
          )} ${receipt?.companyName}`}</span>
        </Grid>
        <TaxNumbers taxRates={receipt.taxRates} />
        {domainByChargerId && (
          <Grid item marginTop={2}>
            <span className={clsx(classes.font14, classes.gray6)}>
              {domainByChargerId.whiteLabellingId === 'IVY'
                ? i18n.t('credit_card_show_changes_ivy')
                : i18n.t('credit_card_show_changes_other')}
            </span>
          </Grid>
        )}
        <Grid container item justifyContent='center' className={classes.p32}>
          <img
            src={`${Assets_URL}?resourceId=logo-svg&chargerId=${receipt.displayId}`}
            className={clsx(classes.h50, classes.maxWidth220)}
            style={{ height: 50, maxWidth: 220 }}
            alt='Company Logo'
          />
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};
