import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DriverSupport, ReceiptSettings } from '../stores/types/env.interface';
import { ColorPaletteObject, Theme } from '../stores/types/theme.interface';
import { defaultColorPalettes } from '../assets/colors/colors.g';

// More info about RTK Query
// https://redux-toolkit.js.org/rtk-query/overview

export const envApi = createApi({
  reducerPath: 'env',
  baseQuery: fetchBaseQuery({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    fetchThemeSettings: builder.query<Theme, void>({
      query: () => ({
        url: `assets?resourceId=theme&domainName=${window.domainName}`, //?hostname=${window.location.hostname}
        method: 'GET',
        legacyApi: true,
      }),
      transformResponse: (response: {
        primary_color?: string;
        secondary_color?: string;
        brand_0: string;
        brand_1: string;
        brand_2: string;
        brand_3: string;
        color_palettes: ColorPaletteObject;
      }) => ({
        primary: response.primary_color || '#117DB8',
        secondary: response.secondary_color || '#E8F7FC',
        // logo will be determined based on the domain
        networkLogo: `/assets?resourceId=logo-svg&domainName=${window.domainName}`,
        loginBackground: `/assets?resourceId=login-background&domainName=${window.domainName}`,
        paymentHeader: `/assets?resourceId=payment-header&domainName=${window.domainName}`,
        favicon: `/assets?resourceId=favicon&domainName=${window.domainName}`,
        chartColor: response.primary_color || '#18A0D7',
        navigationSelectedColor: response.primary_color || '#18A0D7',
        btnHoverColor: response.primary_color || '#117DB8',
        secondaryBtnBgColor: response.secondary_color || '#E8F7FC',
        brand_0: response.brand_0,
        brand_1: response.brand_1,
        brand_2: response.brand_2,
        brand_3: response.brand_3,
        colorPalettes:
          response.color_palettes?.denim ||
          response.color_palettes?.ink ||
          defaultColorPalettes.denim,
      }),
    }),
    fetchSMSSettings: builder.query<any, void>({
      query: () => ({
        url: `assets?resourceId=sms&domainName=${window.domainName}`, //?hostname=${window.location.hostname}
        method: 'GET',
        legacyApi: true,
      }),
    }),
    fetchSupportSettings: builder.query<any, void>({
      query: () => ({
        url: `assets?resourceId=support&domainName=${window.domainName}`, //?hostname=${window.location.hostname}
        method: 'GET',
        legacyApi: true,
      }),
    }),
    fetchDriverSupportSettingsByDomain: builder.query<DriverSupport, void>({
      query: () => ({
        url: `assets?resourceId=driver-support&domainName=${window.domainName}`, //?hostname=${window.location.hostname}
        method: 'GET',
        legacyApi: true,
      }),
    }),
    fetchDriverSupportSettingsByChargerId: builder.query<DriverSupport, string>(
      {
        query: (chargerId: string) => ({
          url: `assets?resourceId=driver-support&chargerId=${chargerId}`, //?hostname=${window.location.hostname}
          method: 'GET',
          legacyApi: true,
        }),
      },
    ),
    fetchReceiptSettings: builder.query<ReceiptSettings, void>({
      query: () => ({
        url: `assets?resourceId=receipt-settings&domainName=${window.domainName}`, //?hostname=${window.location.hostname}
        method: 'GET',
        legacyApi: true,
      }),
    }),
    fetchReceiptSettingsByChargerName: builder.query<ReceiptSettings, string>({
      query: (chargerName: string) => ({
        url: `assets?resourceId=receipt-settings&chargerId=${chargerName}`,
        method: 'GET',
        legacyApi: true,
      }),
    }),
  }),
});

export const {
  useFetchThemeSettingsQuery,
  useFetchReceiptSettingsQuery,
  useFetchReceiptSettingsByChargerNameQuery,
  useLazyFetchReceiptSettingsByChargerNameQuery,
  useFetchSMSSettingsQuery,
  useFetchSupportSettingsQuery,
  useLazyFetchDriverSupportSettingsByChargerIdQuery,
  useLazyFetchDriverSupportSettingsByDomainQuery,
  useFetchDriverSupportSettingsByDomainQuery,
} = envApi;
