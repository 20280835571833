import { makeStyles } from '@mui/styles';
import { ColorType } from '../../assets/colors/colors.g';

export const receiptStyles = makeStyles(() => ({
  root: {
    padding: '8px',
  },
  sectionDivider: {
    padding: '16px',
    backgroundColor: '#F7F7F7',
    color: 'gray',
  },
  right: {
    textAlign: 'right',
  },
  time: {
    fontSize: '10px',
    opacity: 0.54,
    fontWeight: 400,
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E5E8EB',
    borderRadius: '14px',
  },
  borderBottom2: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: '#E5E8EB',
  },
  borderBottom8: {
    borderBottomWidth: 8,
    borderBottomStyle: 'solid',
    borderBottomColor: '#E5E8EB',
  },
  paddingTopZero: {
    paddingTop: 0,
  },
  preLine: {
    whiteSpace: 'pre-line',
  },
  fontInter: {
    fontFamily: 'Inter',
  },
  weight600: {
    fontWeight: 600,
  },
  weight400: {
    fontWeight: 400,
  },
  weight500: {
    fontWeight: 500,
  },
  font14: {
    fontSize: 14,
  },
  py12px16: {
    padding: '12px 16px',
  },
  p0: {
    padding: 0,
  },
  p16: {
    padding: 16,
  },
  p32: {
    padding: 32,
  },
  px16: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  pl4: {
    paddingLeft: 4,
  },
  pt8: {
    paddingTop: 8,
  },
  pt16: {
    paddingTop: 16,
  },
  pt20: {
    paddingTop: 20,
  },
  pt30: {
    paddingTop: 30,
  },
  m0: {
    margin: 0,
  },
  mt2: {
    marginTop: 2,
  },
  mt4: {
    marginTop: 4,
  },
  mt6: {
    marginTop: 6,
  },
  m16: {
    margin: 16,
  },
  mt8: {
    marginTop: 8,
  },
  mt10: {
    marginTop: 10,
  },
  mt12: {
    marginTop: 12,
  },
  mt16: {
    marginTop: 16,
  },
  h50: {
    height: 50,
  },
  maxWidth220: {
    maxWidth: 220,
  },
  width110: {
    width: '110px',
  },
  gray: {
    color: '#77818e',
  },
  gray5: {
    color: ColorType.GREY5,
  },
  gray6: {
    color: ColorType.GREY6,
  },
  darkGray: {
    color: ColorType.BLACK,
  },
  bgDarkGray: {
    backgroundColor: ColorType.BLACK,
  },
  green: {
    color: ColorType.POSITIVE,
  },
  white: {
    color: ColorType.WHITE,
  },
  red: {
    color: ColorType.NEGATIVE,
  },
  roundBorder: {
    borderRadius: '8px',
  },
  displayBlock: {
    display: 'block',
  },
  pointer: {
    cursor: 'pointer',
  },
  itemIcon: {
    alignSelf: 'baseline',
    marginTop: 6,
    minWidth: 32,
  },
  alertsNegative2: {
    color: '#C62828',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  lineHeight20: {
    lineHeight: '20px',
  },
  lineHeight24: {
    lineHeight: '24px',
  },
}));
