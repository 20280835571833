import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';

interface BannerProps {
  type?: string;
  message?: string;
  url?: string;
  onClose: () => void;
}

const Banner: React.FC<BannerProps> = ({
  type = 'info',
  message = '',
  url,
  onClose,
}) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (type) {
      case 'info':
        return <InfoIcon sx={{ color: '#4E5968', fontSize: '24px' }} />;
      case 'warning':
        return <WarningIcon sx={{ color: '#FFB300', fontSize: '24px' }} />;
      case 'error':
        return <ErrorIcon sx={{ color: '#E53935', fontSize: '24px' }} />;
      default:
        return <InfoIcon sx={{ color: '#4E5968', fontSize: '24px' }} />;
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case 'info':
        return '#F2F4F6';
      case 'warning':
        return '#FFF9EB';
      case 'error':
        return '#FDEDED';
      default:
        return '#F2F4F6';
    }
  };

  return (
    <Alert
      icon={getIcon()}
      action={
        <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={onClose}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      }
      sx={{
        backgroundColor: getBackgroundColor(),
        color: '#202223',
        alignItems: 'center',
      }}
    >
      <Typography
        component='span'
        sx={{
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
        }}
      >
        {message}{' '}
        {url && (
          <>
            <Link href={url} target='_blank' rel='noopener noreferrer'>
              {t('click_here')}
            </Link>
          </>
        )}
      </Typography>
    </Alert>
  );
};

export default Banner;
